import React, {useState, useEffect} from "react"

import tronBull from '../../images/buyTrx/tron-bull.png'
import bcPayLogo from '../../images/buyTrx/blockchain.png'

const BuyTrx = () => {
  return(
    <div className="buy-trx-wrap">
      <div className="buy-trx-content">
        <div className="buy-trx-info">
          <img className="buy-trx-logo" src={bcPayLogo} alt="" />
          <p>
            This page is not currently accessible to persons in the U.S.
          </p>
        </div>
        <img className="tron-bull" src={tronBull} alt="" />
      </div>
    </div>
  )
}

export default BuyTrx